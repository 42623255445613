export default {
    content: {
        stages: {
            therapy:
                {
                    title: "Оберіть основну терапію",
                    shortTitle: "Основна терапія",
                    subtitle: "Оберіть діючу речовину",
                    nextBtn: "Далі",
                    switch: [
                        {
                            id: "iapfDiuretic",
                            name: "іАПФ+діуретик",
                        },
                        {
                            id: "braDiuretic",
                            name: "БРА+діуретик",
                        },
                        {
                            id: "iapf",
                            name: "іАПФ",
                        },
                        {
                            id: "bra",
                            name: "БРА",
                        },
                        {
                            id: "bab",
                            name: "БАБ",
                        },
                        {
                            id: "bkk",
                            name: "БКК",
                        },
                    ],
                    drug: [
                        {
                            combination: "iapfDiuretic",
                            id: "enelaprilN",
                            name: "Еналаприл + гідрохлоротіазид",
                            medicalName: "Еналаприл Н-Тева 10 мг + 25 мг №30",
                            calculatorName: "Еналаприл + гідрохлоротіазид 10 мг + 25 мг №30",
                            prices: [95.96, 95.96],
                            altPrices: [130.89, 130.89]
                        },
                        {
                            combination: "iapfDiuretic",
                            id: "lizotiazid",
                            name: "Лізиноприл + гідрохлоротіазид",
                            medicalName: "Лізотіазид-Тева 20 мг + 12,5 мг №30",
                            calculatorName: "Лізиноприл + гідрохлоротіазид 20 мг + 12,5 мг №30",
                            prices: [128.19, 128.19],
                            altPrices: [118.23, 118.23]
                        },
                        {
                            combination: "braDiuretic",
                            id: "valsartanN",
                            name: "Валсартан + гідрохлоротіазид",
                            medicalName: "Валсартан Н-Тева 160 мг + 12,5 мг №30",
                            calculatorName: "Валсартан + гідрохлоротіазид 160 мг + 12,5 мг №30",
                            prices: [154.96, 154.96],
                            altPrices: [307.72, 307.72]
                        },
                        // {
                        //   combination: "braDiuretic",
                        //   id: "lozartanPlus",
                        //   name: "Лозартан-Plus",
                        //   medicalName: "Лозартан Плюс-Тева 50 мг + 12 мг №30",
                        //   calculatorName: "Лозартан-Plus 50 мг + 12 мг №30",
                        //   prices: [0, 0],
                        //   altPrices: [310.07, 0]
                        // },
                        {
                            combination: "iapf",
                            id: "lisinopril",
                            name: "Лізиноприл",
                            medicalName: "Лізиноприл-Тева 10 мг №30",
                            calculatorName: "Лізиноприл 10 мг №30",
                            prices: [133.99, 133.99],
                            altPrices: [93.80, 93.80]
                        },
                        {
                            combination: "iapf",
                            id: "enelapril",
                            name: "Еналаприл",
                            medicalName: "Еналаприл-Тева 10 мг №30",
                            calculatorName: "Еналаприл 10 мг №30",
                            prices: [51.32, 40.02],
                            altPrices: [96.25, 96.25]
                        },
                        {
                            combination: "iapf",
                            id: "ramipril",
                            name: "Раміприл",
                            medicalName: "Раміприл-Тева 5 мг №30",
                            calculatorName: "Раміприл 5 мг №30",
                            prices: [226.12, 226.12],
                            altPrices: [223.35, 223.35]
                        },
                        {
                            combination: "bra",
                            id: "valsartan",
                            name: "Валсартан",
                            medicalName: "Валсартан-Тева 160 мг №30",
                            calculatorName: "Валсартан 160 мг №30",
                            prices: [167.15, 167.15],
                            altPrices: [271.45, 271.45]
                        },
                        {
                            combination: "bra",
                            id: "lozartan",
                            name: "Лозартан",
                            medicalName: "Лозартан-Тева 100 мг №30",
                            calculatorName: "Лозартан 100 мг №30",
                            prices: [101.83, 37.93],
                            altPrices: [81.92, 17.6]
                        },
                        // {
                        //   combination: "bra",
                        //   id: "telmisartan",
                        //   name: "Телмісартан",
                        //   prices: [],
                        //   altPrices: []
                        // },
                        {
                            combination: "bab",
                            id: "bisoprolol",
                            name: "Бісопролол",
                            medicalName: "Бісопролол-Тева 5 мг №30",
                            calculatorName: "Бісопролол 5 мг №30",
                            prices: [46.26, 36.03],
                            altPrices: [216.30, 216.30]
                        },
                        {
                            combination: "bab",
                            id: "nebivolol",
                            name: "Небіволол",
                            medicalName: "Небіволол-Тева 5 мг №28",
                            calculatorName: "Небіволол 5 мг №28",
                            prices: [251.71, 251.71],
                            altPrices: [301.12, 301.12]
                        },
                        {
                            combination: "bkk",
                            id: "lerkanidipin",
                            name: "Лерканідипін",
                            medicalName: "Лерканідипін-Тева 20 мг №28",
                            calculatorName: "Лерканідипін 20 мг №28",
                            prices: [231.13, 231.13],
                            altPrices: [375.41, 375.41]
                        },
                        {
                            combination: "bkk",
                            id: "amlodipine",
                            name: "Амлодипін",
                            medicalName: "Амлодипін-Тева 10 мг №30",
                            calculatorName: "Амлодипін 10 мг №30",
                            prices: [48.22, 32.77],
                            altPrices: [25.86, 10.37]
                        },
                    ],
                },
            inhibitorDiuretic:
                {
                    title: "<span>Додати фіксовану комбінацію<br>інгібітора ренін-ангіотензин-альдостеронової<br>системи з діуретиком?</span>",
                    shortTitle: "Комбінація інгібітора з діуретиком",
                    subtitle: "Оберіть діючу речовину",
                    switch: [
                        {
                            id: "iapfDiuretic",
                            name: "іАПФ+діуретик",
                        },
                        {
                            id: "braDiuretic",
                            name: "БРА+діуретик",
                        },
                        {
                            id: "no",
                            name: "Ні",
                        }
                    ],
                    nextBtn: "Далі",
                    drug: [
                        {
                            combination: "iapfDiuretic",
                            id: "enelaprilN",
                            name: "Еналаприл + гідрохлоротіазид",
                            medicalName: "Еналаприл Н-Тева 10 мг + 25 мг №30",
                            calculatorName: "Еналаприл + гідрохлоротіазид 10 мг + 25 мг №30",
                            prices: [95.96, 95.96],
                            altPrices: [130.89, 130.89]
                        },
                        {
                            combination: "iapfDiuretic",
                            id: "lizotiazid",
                            name: "Лізиноприл + гідрохлоротіазид",
                            medicalName: "Лізотіазид-Тева 20 мг + 12,5 мг №30",
                            calculatorName: "Лізиноприл + гідрохлоротіазид 20 мг + 12,5 мг №30",
                            prices: [128.19, 0],
                            altPrices: [118.23, 0]
                        },
                        {
                            combination: "braDiuretic",
                            id: "valsartanN",
                            name: "Валсартан + гідрохлоротіазид",
                            medicalName: "Валсартан Н-Тева 160 мг + 12,5 мг №30",
                            calculatorName: "Валсартан + гідрохлоротіазид 160 мг + 12,5 мг №30",
                            prices: [154.96, 154.96],
                            altPrices: [307.72, 307.72]
                        },
                        // {
                        //   combination: "braDiuretic",
                        //   id: "lozartanPlus",
                        //   name: "Лозартан-Plus",
                        //   medicalName: "Лозартан Плюс-Тева 50 мг + 12 мг №30",
                        //   calculatorName: "Лозартан-Plus 50 мг + 12 мг №30",
                        //   prices: [0, 0],
                        //   altPrices: [310.07, 0]
                        // },
                    ],
                },
            diuretric:
                {
                    title: "Додати діуретик?",
                    shortTitle: "Діуретик",
                    subtitle: "Оберіть діючу речовину",
                    switch: [
                        {
                            id: "yes",
                            name: "Так",
                        },
                        {
                            id: "no",
                            name: "Ні",
                        },
                    ],
                    nextBtn: "Далі",
                    drug: [
                        {
                            id: "torasemid",
                            name: "Торасемід",
                            medicalName: "Торасемід-Тева 5 мг №30",
                            calculatorName: "Торасемід 5 мг №30",
                            prices: [167.26, 167.26],
                            altPrices: [215.08, 215.08]
                        },
                        {
                            id: "indapamid",
                            name: "Індапамід",
                            medicalName: "Індапамід-Тева SR 1,5 мг №30",
                            calculatorName: "Індапамід 1,5 мг №30",
                            prices: [137.06, 137.06],
                            altPrices: [164.66, 164.66]
                        }
                    ],
                },
            inhibitor:
                {
                    title: "<span>Додати інгібітори<br>ренін-ангіотензин-альдостеронової<br>системи?</span>",
                    shortTitle: "Інгібітор",
                    subtitle: "Оберіть діючу речовину",
                    switch: [
                        {
                            id: "iapf",
                            name: "іАПФ",
                        },
                        {
                            id: "bra",
                            name: "БРА",
                        },
                        {
                            id: "no",
                            name: "Ні",
                        }
                    ],
                    nextBtn: "Далі",
                    drug: [
                        {
                            combination: "iapf",
                            id: "lisinopril",
                            name: "Лізиноприл",
                            medicalName: "Лізиноприл-Тева 10 мг №30",
                            calculatorName: "Лізиноприл 10 мг №30",
                            prices: [133.99, 133.99],
                            altPrices: [93.80, 93.80]
                        },
                        {
                            combination: "iapf",
                            id: "enelapril",
                            name: "Еналаприл",
                            medicalName: "Еналаприл-Тева 10 мг №30",
                            calculatorName: "Еналаприл 10 мг №30",
                            prices: [51.32, 40.02],
                            altPrices: [96.25, 96.25]
                        },
                        {
                            combination: "iapf",
                            id: "ramipril",
                            name: "Раміприл",
                            medicalName: "Раміприл-Тева 5 мг №30",
                            calculatorName: "Раміприл 5 мг №30",
                            prices: [226.12, 226.12],
                            altPrices: [223.35, 223.35]
                        },
                        {
                            combination: "bra",
                            id: "valsartan",
                            name: "Валсартан",
                            medicalName: "Валсартан-Тева 160 мг №30",
                            calculatorName: "Валсартан 160 мг №30",
                            prices: [167.15, 167.15],
                            altPrices: [271.45, 271.45]
                        },
                        {
                            combination: "bra",
                            id: "lozartan",
                            name: "Лозартан",
                            medicalName: "Лозартан-Тева 100 мг №30",
                            calculatorName: "Лозартан 100 мг №30",
                            prices: [101.83, 37.93],
                            altPrices: [81.92, 17.6]
                        },
                    ],
                },
            bab:
                {
                    title: "Додати БАБ?",
                    shortTitle: "БАБ",
                    subtitle: "Оберіть діючу речовину",
                    switch: [
                        {
                            id: "yes",
                            name: "Так",
                        },
                        {
                            id: "no",
                            name: "Ні",
                        },
                    ],
                    nextBtn: "Далі",
                    drug: [
                        {
                            id: "bisoprolol",
                            name: "Бісопролол",
                            medicalName: "Бісопролол-Тева 5 мг №30",
                            calculatorName: "Бісопролол 5 мг №30",
                            prices: [46.26, 36.03],
                            altPrices: [216.30, 216.30]
                        },
                        {
                            id: "nebivolol",
                            name: "Небіволол",
                            medicalName: "Небіволол-Тева 5 мг №28",
                            calculatorName: "Небіволол 5 мг №28",
                            prices: [251.71, 251.71],
                            altPrices: [301.12, 301.12]
                        },
                    ],
                },
            bkk:
                {
                    title: "Додати БКК?",
                    shortTitle: "БКК",
                    subtitle: "Оберіть діючу речовину",
                    switch: [
                        {
                            id: "yes",
                            name: "Так",
                        },
                        {
                            id: "no",
                            name: "Ні",
                        },
                    ],
                    nextBtn: "Далі",
                    drug: [
                        {
                            id: "lerkanidipin",
                            name: "Лерканідипін",
                            medicalName: "Лерканідипін-Тева 20 мг №28",
                            calculatorName: "Лерканідипін 20 мг №28",
                            prices: [231.13, 231.13],
                            altPrices: [375.41, 375.41]
                        },
                        {
                            id: "amlodipine",
                            name: "Амлодипін",
                            medicalName: "Амлодипін-Тева 10 мг №30",
                            calculatorName: "Амлодипін 10 мг №30",
                            prices: [48.22, 32.77],
                            altPrices: [25.86, 10.37]
                        },
                    ],
                },
            antiTromboletic:
                {
                    title: "Додати антитромботичний<br>лікарський засіб?",
                    shortTitle: "Антитромботичний лікарський засіб",
                    subtitle: "Оберіть діючу речовину",
                    switch: [
                        {
                            id: "yes",
                            name: "Так",
                        },
                        {
                            id: "no",
                            name: "Ні",
                        },
                    ],
                    nextBtn: "Далі",
                    drug: [
                        {
                            id: "ask",
                            name: "АСК",
                            medicalName: "АСК-Тева 75мг №30",
                            calculatorName: "Ацетилсаліцилова кислота 75 мг №30",
                            prices: [33.96, 16.43],
                            altPrices: [78.17, 78.17]
                        },
                        {
                            id: "klopidogrel",
                            name: "Клопідогрель",
                            medicalName: "Клопідогрел-Тева 75 мг №30",
                            calculatorName: "Клопідогрель 75 мг №30",
                            prices: [115.70, 79.68],
                            altPrices: [989.42, 989.42]
                        },
                    ],
                },
            hipolipidemic:
                {
                    title: "Додати гіполіпідемічний<br>лікарський засіб?",
                    shortTitle: "Гіполіпідемічний лікарський засіб",
                    subtitle: "Оберіть діючу речовину",
                    switch: [
                        {
                            id: "yes",
                            name: "Так",
                        },
                        {
                            id: "no",
                            name: "Ні",
                        },
                    ],
                    nextBtn: "Далі",
                    drug: [
                        {
                            id: "samvastatin",
                            name: "Симвастатин",
                            medicalName: "Симвастатин-Тева 40 мг №30",
                            calculatorName: "Симвастатин 40 мг №30",
                            prices: [74.83, 0],
                            altPrices: [81.96, 9.04]
                        },
                        {
                            id: "atorvastatin",
                            name: "Аторвастатин",
                            medicalName: "Аторвастатин-Тева 20 мг №30",
                            calculatorName: "Аторвастатин 20 мг №30",
                            prices: [251.55, 251.55],
                            altPrices: [306.30, 306.30]
                        },
                        {
                            id: "rosuvastatin",
                            name: "Розувастатин",
                            medicalName: "Розувастатин-Тева 10 мг №30",
                            calculatorName: "Розувастатин 10 мг №30",
                            prices: [97.72, 97.72],
                            altPrices: [200.06, 200.06]
                        }
                    ],
                },
            configurator:
                {
                    title: "Перевірте введені дані",
                    subtitle: "Якщо все вірно – натисніть кнопку <strong>«Розрахувати»</strong> щоб порівняти фармакоекономіку<br>за 30 днів при лікуванні препаратами лінійки Тева та аналогами.",
                    btn: "Розрахувати",
                    popup: {
                        btn: "Зберегти зміни"
                    }
                },
            result:
                {
                    title: "Фармакоекономіка",
                    subtitle: "приблизної схеми лікування з прийомом 1 раз в день",
                    mode: [
                        "За 30 днів при<br>звичайних цінах",
                        'За 30 днів з урахуванням<br>"доступні ліки"',
                        "За рік при<br>звичайних цінах",
                        'За рік з урахуванням<br>"доступні ліки"'
                    ],
                    table: [
                        "МНН",
                        "Препарати<br>Тева, <span class='small'>грн*</span>",
                        "Аналог<br><span class='small'>(лідер категорії), грн**</span>"
                    ],
                    summary: "Сума лікування",
                    footer: [
                        "* Ціна однієї упаковки за середньозваженими роздрібними цінами в Україні (окрім тимчасово окупованих територій) за період 03.2024 за інформацією з Бази даних «Pharmxplorer» © ТОВ «Проксіма Рісерч», 2009.",
                        "** Лідер за обсягами роздрібних продажів в грошовому виразі у відповідній категорії АТХ у період 03.2024 в Україні (окрім тимчасово окупованих територій). За інформацією з Бази даних «Pharmxplorer» © ТОВ «Проксіма Рісерч», 2009.",
                        "*** Йдеться про препарат, який займає друге місце за обсягами роздрібних продажів в грошовому виразі у період 04.2024 в Україні (окрім тимчасово окупованих територій). За інформацією з Бази даних «Pharmxplorer» © ТОВ «Проксіма Рісерч», 2009."
                    ],
                    btn: "Спробувати ще раз",
                    packPopup: [
                        "За 30 днів при звичайних цінах",
                        'За 30 днів з урахуванням "доступні ліки"',
                        "За рік при звичайних цінах",
                        'За рік з урахуванням "доступні ліки"'
                    ]
                }
        }
    },

    popup: {
        references: {
            list: [
                "іАПФ — інгібітор ангіотензин-перетворюючого ферменту",
                "БРА — блокатор рецепторів ангіотензину ІІ",
                "БАБ — бета - адреноблокатор",
                "БКК — блокатор кальцієвих каналів"
            ],
        },
        "research-design": {},
    },
};
